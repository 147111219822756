<template>
	<div>
		<!-- 公共头部 -->
		<Header ></Header>
		
		<div class="section1">
			<div class="item" v-for="(item,index) in list" :key="index">
				<!-- 未通过 #ff2e2e 快递中 #009d30 审核中 #ff6000 已上传 #c7c7c7 -->
				<div class="cate-title">{{item.title}}
					<font v-if="item.status == '审核中'" color="#ff6000">（审核中）</font>
					<font v-if="item.status == '已上传'" color="#009d30">（已上传）</font>
					<font v-if="item.status == '未通过'" color="#ff2e2e">（未通过）</font>
					<font v-if="item.status == '未上传'" color="#c7c7c7">（未上传）</font>
				</div>
				<div class="box">
					<div class="pic">
						<img class="icon" :src="item.img" />
					</div>
					<div class="content">
						<div class="title">培训校区：{{item.school_title}}</div>
						<div class="desc">报名时间：{{item.addtime}}</div>
						<div class="button-group">
							
							<router-link :to="'/dataUpload/upload?id=' + item.id + '&title=' + item.title" class="btn" v-if="item.status == '未上传'">立即上传</router-link>
							<div class="btn active" v-if="item.status == '审核中'">审核中</div>
							<router-link :to="'/dataUpload/upload?id=' + item.id + '&title=' + item.title" class="btn" v-if="item.status == '未通过'">重新上传</router-link>
							<div class="btn active" v-if="item.status == '已上传'">已上传</div>
							
							<a v-if="item.path" :href="item.path" download class="btn btn1">资料下载</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '资料上传',
				list: []
			}
		},
		computed: {
			uid() {
					return this.$store.state.uid;
			}
		},
		activated(){
			this.getUserInfo();
		},
		deactivated() {
			this.list = [];
		},
		methods: {
			// 获取项目列表
			async getList() {
				var uid = this.uid;
				var data = {
					uid
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/study/materialReceive', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('资料上传 项目列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var list = res.data;
					for(var i = 0; i < list.length; i++) {
						list[i]['addtime'] = this.utils.js_date_time(list[i]['addtime'],2);
						if(list[i]['img'] && list[i]['img'].substring(0,4) != 'http'){
							list[i]['img'] = this.$store.state.http + list[i]['img'];
						}
						if(list[i]['path'] && list[i]['path'].substring(0,4) != 'http'){
							list[i]['path'] = this.$store.state.http + list[i]['path'];
						}
					}
					this.list = list;
				} else {
					this.list = [];
				}
			},
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
					wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return
					}
					wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getList();
				}
			},
		},
	};
</script>

<style scoped>
	.section1{
		margin-top: 0.4rem;
		margin-bottom: 0.6rem;
	}
	.section1 .item{
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
		margin-top: 0.4rem;
	}
	.section1 .item:first-child{
		margin-top: 0;
	}
	.section1 .item .cate-title{
		height: 0.72rem;
		display: flex;
		align-items: center;
		font-size: 0.3rem;
		color: #333333;
		padding: 0 0.3rem;
		border-bottom: 0.02rem solid #e0e0e0;
	}
	.section1 .item .box{
		padding: 0.2rem 0.3rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.section1 .item .box .pic{
		width: 1.6rem;
		height: 1.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
	}
	.section1 .item .box .pic .icon{
		max-height: 100%;
	}
	.section1 .item .box .content{
		width: 5rem;
		/* height: 1.6rem; */
	}
	.section1 .item .box .content .title{
		font-size: 0.26rem;
		color: #333333;
		margin-bottom: 0.18rem;
	}
	.section1 .item .box .content .desc{
		font-size: 0.26rem;
		color: #333333;
	}
	.section1 .item .box .content .button-group{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 0.3rem;
	}
	.section1 .item .box .content .button-group .btn{
		width: 1.4rem;
		height: 0.44rem;
		font-size: 0.24rem;
		color: #333333;
		border: 0.02rem solid #333333;
		border-radius: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.3rem;
	}
	.section1 .item .box .content .button-group .btn:last-child{
		margin-right: 0;
	}
	.section1 .item .box .content .button-group .active{
		border: 0.02rem solid #c7c7c7;
		color: #c7c7c7;
	}
	.section1 .item .box .content .button-group .btn1{
		border: 0.02rem solid #ff6000;
		color: #ff6000;
	}
</style>